import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import Menu from "../../components/dropdown/Menu";
import styled from "styled-components";
import DownB from "../../assets/DownB.png";
import { NativeDeviceCene } from "../../models/NativeDeviceDescriptions";
import useDevicesContext from "../../contexts/devices";
import ManipuladorQuerys from "./manipulador";
import { DeviceActionDefine } from "../../controllers/NativeDevice";
import ToggleSwitch from "../../components/toggle/ToggleSwitch";

const Div1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-right: 3%; */
    /* background: red; */
`;
const Text2 = styled.h2`
    font-size: 16px;
    font-weight: 500;
    font-family: "Manrope";
    color: #1b242d;
    /* line-height: 0%; */

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Text3 = styled.h1`
    font-size: 14px;
    font-weight: 600;
    font-family: "Manrope";
    color: #737b8c;
    /* margin: -1% 0 -5%; */
    /* font-weight: 0%; */

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Opcoes = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
    background: #eff3f6;
    border-radius: 16px;
    min-width: 800px;
    max-width: 1400px;
    width: 100%;
`;

const SessionOpcoes = styled.div<{ visible?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 10px 30px;

    ${({ visible }) => !visible && "display: none;"}

    >div {
        margin-bottom: 10px;
    }
`;

export interface OptionsCenasProps extends NativeDeviceCene {
    ids: string[];
    onChange: (cene: Partial<NativeDeviceCene>) => void;
}

const OptionsCenas: FC<OptionsCenasProps> = ({ ids, id: initialID, invertToOff, path: initialPath, query, onChange }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [id, setId] = useState<string>("");
    const [action, setAction] = useState<DeviceActionDefine | undefined>();
    const [listOfActions, setListOfActions] = useState<DeviceActionDefine[]>([]);
    const [querys, setQuerys] = useState<Record<string, any>>({});

    const { devices } = useDevicesContext();

    const deviceSelected = devices.find((device) => device.data.deviceID === id);

    useEffect(() => {
        setListOfActions(deviceSelected?.controller?.listOfActions() || []);
        deviceSelected?.controller?.on("actions", (actions) => {
            setListOfActions(actions);
        });
    }, [deviceSelected?.controller]);

    const listOfNamesActions = listOfActions.map((action) => action.name) || [];

    useEffect(() => {
        setId(initialID);
    }, [initialID]);

    useEffect(() => {
        const da = listOfActions.find((action) => action.path === initialPath);
        setAction(da);
    }, [initialPath, listOfActions]);

    useEffect(() => {
        const newQuerys: Record<string, any> = {};
        const qq = query?.split("&");
        qq?.forEach((q) => {
            const [key, value] = q.split("=");
            newQuerys[key] = value;
        });
        setQuerys(newQuerys);
    }, [query]);

    const handlesChangeID = (id: string) => {
        setId(id);
        onChange({ id });
    };

    const handleAction = (action?: DeviceActionDefine) => {
        setAction(action);
        if (action) onChange({ path: action.path });
    };

    const handleQuery = useCallback(
        (query: Record<string, any>) => {
            setQuerys((old) => {
                Object.keys(query).forEach((key) => {
                    if (query[key] === undefined) delete old[key];
                    else old[key] = query[key];
                });
                onChange({
                    query: Object.keys(old)
                        .map((key) => key && old[key] && `${key}=${old[key]}`)
                        .filter(Boolean)
                        .join("&"),
                });
                return old;
            });
        },
        [onChange]
    );

    return (
        <Opcoes>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "100px",
                    width: "100%",
                }}
            >
                <div style={{ width: "40px", display: "flex", justifyContent: "center", alignContent: "center" }}>
                    <Text2>1</Text2>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "100%",
                        height: "20px",
                        padding: "0 20px",
                    }}
                >
                    <div style={{}}>
                        <Div1 style={{ marginBottom: "10px" }}>
                            <Text3>Em uso</Text3>
                            <Menu onChange={handlesChangeID} value={id} opts={ids} />
                        </Div1>
                    </div>
                </div>
                <img
                    onClick={() => {
                        setIsOpen((e) => !e);
                    }}
                    style={!!isOpen ? { cursor: "pointer", transform: "rotate(180deg)" } : { cursor: "pointer" }}
                    src={DownB}
                    alt=""
                />
            </div>
            <SessionOpcoes visible={isOpen}>
                <div style={{ width: "90%", height: "1.5px", background: "#c9ccd1", borderRadius: "10%" }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Text3>Inverter ação para desligar</Text3>
                    <ToggleSwitch
                        value={invertToOff}
                        onChange={(e) => {
                            onChange({ invertToOff: e });
                        }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Text3>Ação</Text3>
                    <Menu
                        onChange={(e) => {
                            handleAction(listOfActions.find((action) => action.name === e));
                        }}
                        opts={listOfNamesActions}
                        value={action?.name}
                        placeholder="Ação"
                    />
                </div>
                {action && (
                    <>
                        <div style={{ width: "90%", height: "1.5px", background: "#c9ccd1", borderRadius: "10%" }} />
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Text3>Manipulando</Text3>
                        </div>
                        <ManipuladorQuerys action={action} query={query} onChange={handleQuery} />
                    </>
                )}
            </SessionOpcoes>
        </Opcoes>
    );
};

export default OptionsCenas;
