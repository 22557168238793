import { collection, doc, query, setDoc, where } from "firebase/firestore";
import { firestore, updated_at_timestamp } from "../../middlewares/firebase";

export interface Device {
    // device info
    deviceID: string;
    ip: string;
    model: string;
    deviceName: string;
    name: string;
    version: string;
    serialNumber: string;
    type: string;
    date: string;
    fab: string;

    // user info
    duid: string;
    uid: string;
    huid: string;

    // database info
    id: string;
    createdAt: Date;
    updatedAt: Date;
}

export type DeviceSetter = Omit<Device, "id" | "createdAt" | "updatedAt" | "huid" | 'duid'>;

const deviceCollection = collection(firestore, "devices");

export const deviceQuerys = {
    byUserID: (userId: string) => query(deviceCollection, where("uid", "==", userId)),
    byProjectID: (projectId: string) => query(deviceCollection, where("huid", "==", projectId)),
    byDeviceID: (deviceId: string) => query(deviceCollection, where("deviceID", "==", deviceId)),
    byUserAndProjectID: (userId: string, projectId: string) => query(deviceCollection, where("uid", "==", userId), where("huid", "==", projectId)),

};

export const createNewDevice = async (projectID: string, device: DeviceSetter) => {
    const docnew = doc(deviceCollection);
    const newDevice = {
        ...device,
        huid: projectID,
        createdAt: updated_at_timestamp,
        updatedAt: updated_at_timestamp,
        duid: docnew.id,
    };
    console.log(newDevice)
    return await setDoc(docnew, newDevice);
};
