import { signOut } from "firebase/auth";
import React, { FC, useEffect } from "react";
import { auth } from "../../middlewares/firebase";
import { useNavigate } from "react-router-dom";

export interface SingOutProps {}

const SingOut: FC<SingOutProps> = ({}) => {
    const navigate = useNavigate();
    useEffect(() => {
        signOut(auth);
        navigate("/login");
    }, [navigate]);
    return <></>;
};

export default SingOut;
