import React from "react";
import { SessionNotFound } from "./styles";
import styled from "styled-components";

const ControlerSession = styled.div`
    width: 100%;
    min-height: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
`;

const NotFoundPage: React.FC = () => {
    return (
        <SessionNotFound>
            <ControlerSession>
                <h1>404</h1>

                <h2>Page not found</h2>

                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>

                <a href="/">home page</a>
            </ControlerSession>
        </SessionNotFound>
    );
};

export default NotFoundPage;
