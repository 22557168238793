import { getAuth, User } from "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import { auth, firestore } from "../../middlewares/firebase";
import createCTX from "../../utils/createCTX";
import useAuthContext from "../auth";
import { createNewProject, Project, projectQuerys, ProjectSetter } from "../../models/FB/projects";
import { onSnapshot } from "firebase/firestore";

interface ProjectsInterface {
    projects: Project[];
    createNewProject: (projec: ProjectSetter) => void;
}

const [useProjectsContext, ProjectsContext] = createCTX<ProjectsInterface>();

const ProjectsProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { user } = useAuthContext();
    const [projects, setProjects] = useState<Project[]>([]);

    useEffect(() => {
        if (user?.uid) {
            const un = onSnapshot(projectQuerys.byUserID(user.uid), (snapshot) => {
                console.log({ snapshot });
                const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Project));
                setProjects(data);
            }, console.error);
            return () => un();
        }
    }, [user?.uid]);

    const _createNewProject = useCallback(
        async (projec: ProjectSetter) => {
            if (user?.uid) {
                (projec as any).uid = user.uid;
                createNewProject(projec);
            }
        },
        [user?.uid]
    );

    const value: ProjectsInterface = {
        projects,
        createNewProject: _createNewProject,
    };

    return <ProjectsContext value={value}>{children}</ProjectsContext>;
};

export default useProjectsContext;
export { ProjectsProvider };
