import styled from "styled-components";

export const Page = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    min-height: 100vh;
    width: 100%;
    position: relative;
`;

export const NavSession = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 400px;
    /* height: 100%; */
    background: #eff3f6;
    padding: 20px 5px;
`;

export const BodySession = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    flex: 1;
    align-items: flex-start;
    justify-content: start;
`;

export const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0;
`;
