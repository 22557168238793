import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { firestore, updated_at_timestamp } from "../../middlewares/firebase";

export interface Project {
    id: string;
    uid: string;
    name: string;
    description?: string;
    imageURL?: string;
    createdAt: Date;
    updatedAt: Date;
}

export type ProjectSetter = Omit<Project, "id" | "createdAt" | "updatedAt" | "uid">;

const projectCollection = collection(firestore, "projects");

export const projectQuerys = {
    byUserID: (userId: string) => query(projectCollection, where("uid", "==", userId)),
};

export const getProjecsByUserId = async (userId: string) => {
    const querySnapshot = await getDocs(projectQuerys.byUserID(userId));
    return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Project));
};

export const createNewProject = async (project: ProjectSetter) => {
    const newProject = {
        ...project,
        createdAt: updated_at_timestamp,
        updatedAt: updated_at_timestamp,
    };
    console.log(newProject);
    return await setDoc(doc(projectCollection), newProject);
};
