import React, { FC, useState } from "react";
import styled, { css } from "styled-components";
import Setab from "../../assets/Setab.png";
import { useNavigate } from "react-router-dom";
import { Num, Title, Txt } from "../Projetos/style";
import useDevicesContext from "../../contexts/devices";

const Text = styled.h2`
    font-family: "Manrope";
    font-weight: 700;
    font-size: 16px;
    line-height: 0%;
    color: #1b242d;
    /* text-align: end;
    align-items: flex-end; */
    /* padding: 0 5%; */

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Dispositivos = styled.div<{ active?: boolean; top?: boolean; bottoen?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 249px;
    /* height: 58px; */
    background: #eff3f6;
    /* border-radius: 16px; */
    /* margin: 4% 0; */
    /* e c t t d b t t*/
    /* box-shadow: #25313d44 3px 3px 6px 0px inset, #25313d16 -3px -3px 6px 1px inset; */
    box-shadow: #25313d44 3px 0px 6px 0px inset, #25313d16 -3px -3px 6px 1px inset;
    border: 0;
    cursor: pointer;

    ${({ top }) => {
        if (top) {
            return css`
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
            `;
        }
    }}

    ${({ bottoen }) => {
        if (bottoen) {
            return css`
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
            `;
        }
    }}

    ${({ active }) => {
        if (active) {
            return css`
                background: #cfdbe0;
            `;
        }
    }}

    :hover {
        background: #cfdbe0;
        border-color: transparent;
    }
`;

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    flex: 1;
    min-width: 268px;
    min-height: 100%;
    background: #eff3f6;
`;

const Config = styled.button<{ disable?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 191px;
    height: 26px;
    border-radius: 16px;
    border-color: #505edc;
    background-color: #cfdbe0;
    margin-bottom: 10px;
    cursor: pointer;
    font-family: "Manrope";
    font-weight: 700;
    font-size: 14px;
    line-height: 0%;
    color: #505edc;

    :disabled {
        background: #eff3f6;
        border-color: #eff3f6;
        color: #505edc;
        cursor: not-allowed;
    }

    :hover {
        background: #505edc;
        border-color: transparent;
        color: #fff;
    }
`;

export interface MenuHomeProps {}

const MenuHome: FC<MenuHomeProps> = ({}) => {
    const [activeButton, setActiveButton] = useState("");
    const navigate = useNavigate();
    const { devices } = useDevicesContext();

    return (
        <>
            <Frame>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "0 5%" }}>
                    <Txt>
                        <Title>Dispositivos</Title>
                        <Num>({devices.length})</Num>
                    </Txt>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", paddingBottom: "5%" }}>
                        {devices.map((device, i, arr) => {
                            const lastIndex = arr.length - 1;
                            return (
                                <Dispositivos
                                    key={`device-${device.data.id}`}
                                    top={i === 0}
                                    bottoen={i === lastIndex}
                                    onClick={() => {
                                        if (activeButton === device.data.id) {
                                            setActiveButton("");
                                        } else {
                                            setActiveButton(device.data.id);
                                        }
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "249px", height: "58px" }}>
                                        <Text style={{ fontSize: "14px" }}>{device.data.name}</Text>
                                        <img src={Setab} alt="" />
                                    </div>
                                    <div
                                        style={
                                            activeButton === device.data.id
                                                ? { display: "flex", flexDirection: "column", alignItems: "center", marginTop: "3%" }
                                                : { display: "none" }
                                        }
                                    >
                                        <Config disabled>Atualizar</Config>
                                        <Config
                                            onClick={() => {
                                                navigate(`cenas/${device.data.id}`);
                                            }}
                                        >
                                            Cenas/Botões
                                        </Config>
                                        <Config disabled>Informações</Config>
                                        <Config disabled>WiFi</Config>
                                    </div>
                                </Dispositivos>
                            );
                        })}
                    </div>
                </div>
            </Frame>
        </>
    );
};

export default MenuHome;
