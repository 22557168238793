import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Title } from "../../Projetos/style";
import Button from "../../../components/buttom";
import ListOfAddDevices from "./listOfDevices";
import { useLocalizeDevices } from "../../../hooks/localizeDevices";
import { createNewDevice } from "../../../models/FB/device";
import { P } from "../../../components/dropdown";
import useAuthContext from "../../../contexts/auth";

export interface AddDevicesFromIPProps {
    projectID?: string;
}

const AddDevicesFromIP: FC<AddDevicesFromIPProps> = ({ projectID }) => {
    // coletar o state do react router dom para saber se o usuario esta adicionando um novo dispositivo ao projeto
    const { user } = useAuthContext();
    const location = useLocation();
    const navigate = useNavigate();
    const { ip } = (location.state as { ip?: string }) || {};
    const [open, setOpen] = useState(!!ip);

    // useEffect(() => {
    //     if (ip) {
    //         // setTimeout(() => {
    //             setOpen(true);
    //         // }, 1000);
    //     }
    // }, [ip]);

    const exit = useCallback(() => {
        setOpen(false);
        navigate(".", { replace: true });
    }, [navigate]);

    const { devicesLocalizes, localizeAllDevices, loading } = useLocalizeDevices({ initialIpDevice: ip || "" });

    useEffect(() => {
        let timeOut: NodeJS.Timeout;
        if (open) {
            timeOut = setTimeout(() => {
                localizeAllDevices();
            }, 500);
        }
        return () => {
            clearTimeout(timeOut);
        };
    }, [localizeAllDevices, open]);

    const addAllDevices = useCallback(async () => {
        try {
            if (projectID && user?.uid) {
                await Promise.all(
                    devicesLocalizes.map((device) =>
                        createNewDevice(projectID, {
                            date: device.date,
                            deviceName: device.deviceName,
                            name: device.deviceName,
                            ip: device.ip,
                            deviceID: device.id,
                            model: device.model,
                            type: device.type,
                            serialNumber: device.serialNumber,
                            version: device.version,
                            uid: user.uid,
                            fab: 'Xcene',
                        })
                    )
                );
            }
            exit();
        } catch (error) {
            console.log(error);
        }
    }, [devicesLocalizes, exit, projectID, user?.uid]);

    return (
        <>
            <Modal isOpen={open} onRequestClose={exit}>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "90vh", padding: "0 20px" }}>
                    <div style={{ width: "100%", padding: "10px 0 " }}>
                        <Title>Adicionar dispositivo</Title>
                    </div>
                    <div style={{ flex: 1, overflow: "scroll" }}>
                        <Button onClick={() => localizeAllDevices()} loading={loading}>
                            Localizar
                        </Button>
                        <ListOfAddDevices devices={devicesLocalizes} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Button onClick={addAllDevices}>Adicionar</Button>
                        <Button onClick={exit}>Cancelar</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddDevicesFromIP;
