import React, { FC, useCallback, useMemo } from "react";
import { DeviceActionDefine, DeviceActionDefineParam } from "../../controllers/NativeDevice";
import MenuParamsCene from "./dpCenes";

export interface ManipuladorQuerysProps {
    action: DeviceActionDefine;
    query: string;
    onChange?: (e: any) => void;
}
/**
 * tipos possiveis de parametros
 * 1 - string
 * 2 - number
 * 3 - boolean
 * 4 - chosen
 * 5 - array
 */

const Option = ({ name, params, type, query, onChange }: DeviceActionDefineParam & { query: string; onChange?: (e: any) => void }) => {
    const getValueFromKey = useCallback(
        (key: string) => {
            // deve coletar tudo que estiver entre o nome do parametro e o proximo parametro ou o até o '&' ou o final da string
            const [, value] = query.match(new RegExp(`${key}=([^&]*)`)) || [];
            return value;
        },
        [query]
    );

    const types = useMemo<DeviceActionDefineParam[]>(() => {
        if (type.startsWith("Array")) {
            const [, size, _type] = type.match(/Array\[(\d+)\]:(.*)/) || [];
            return Array.from({ length: Number(size) }, () => ({ name, params, type: _type })).map((param, index) => ({ ...param, name: `${name}${index}` }));
        } else {
            return [{ name, params, type }];
        }
    }, [name, params, type]);

    const InputDef = useCallback(
        ({ type, params, name }: DeviceActionDefineParam) => {
            switch (type) {
                case "String":
                    return <input type="text" placeholder={name} />;
                case "Number":
                    return <input type="number" placeholder={name} />;
                case "Boolean":
                    return (
                        <div>
                            <MenuParamsCene
                                opts={["", ...params]}
                                value={getValueFromKey(name)}
                                onChange={(e) => {
                                    onChange?.({
                                        [name]: e === "" ? undefined : e,
                                    });
                                }}
                            />
                        </div>
                    );
                // case "Chosen":
                //     return <input type="text" placeholder={name} />;
                default:
                    return <input type="text" placeholder={name} />;
            }
        },
        [getValueFromKey, onChange]
    );

    return (
        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
            {types.map(({ name, params, type }) => {
                return (
                    <div
                        key={`Options-chosen-action-${name}`}
                        style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }}
                    >
                        <h4 style={{ textAlign: "center" }}>{name}</h4>
                        <InputDef type={type} params={params} name={name} />
                    </div>
                );
            })}
        </div>
    );
};

const ManipuladorQuerys: FC<ManipuladorQuerysProps> = ({ action, query, onChange }) => {
    const { params, path } = action;

    // const onChange = useCallback((e: any) => {
    //     console.log(e);
    // }, []);

    return (
        <>
            {params.map(({ name, params, type }, i) => {
                return (
                    <div key={`opt-${name}-${path}-${i}`} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <Option name={name} params={params} type={type} query={query} onChange={onChange} />
                    </div>
                );
            })}
        </>
    );
};

export default ManipuladorQuerys;
