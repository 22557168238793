import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "./contexts/auth";
import "./index.css";
import Routers from "./pages";
import reportWebVitals from "./reportWebVitals";
// import LayoutPage from "./utils/layoutThemeProvider";
import "./middlewares/firebase";
import { ProjectsProvider } from "./contexts/projecs";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const eva = require('eva-icons');

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <AuthProvider>
        <ProjectsProvider>
            <React.StrictMode>
                <RouterProvider router={Routers} />
            </React.StrictMode>
        </ProjectsProvider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
