import React, { useEffect } from "react";
import styled from "styled-components";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";

const ControllerSession = styled.div`
    width: 300px;
    height: 100vh;
    background: var(--grayscale-light, #eff3f6);
    box-shadow: 1px 2px 6px 0px rgba(37, 49, 61, 0.29) inset;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-flow: 30px;
`;

// const Session

const DeviceLocalConfigPage: React.FC = () => {
    // const listOfAcct = useLoaderData() as Array<string>;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const ip = searchParams.get("device") || "";

    useEffect(() => {
        if (ip) navigate(`/?ip=${ip}`, { replace: true });
    }, [ip, navigate]);
    return <></>;
};

export default DeviceLocalConfigPage;
