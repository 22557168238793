import { Formik } from "formik";
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { auth } from "../../middlewares/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import useAuthContext from "../../contexts/auth";
import Input from "../../components/input";
import { authMessageByCode } from "../../utils/authMenssagesCode";
import Button from "../../components/buttom";

const Page = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    height: 100vh;
`;

const Cont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 327.5px;
    /* background: red; */
`;

const Frame = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 327.5px;
`;

const Frame1 = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    /* width: 327.5px; */
    /* height: 128px; */
    /* margin-top: 6%; */
    /* background: red; */
`;

const Title = styled.h1`
    font-size: 30px;
    font-weight: 700;
    font-family: "Manrope";
    line-height: 0%;

    @media screen and (max-width: 480px) {
        /* width: 350px; */
    }
`;

const Txt = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
`;

const Foto = styled.div`
    display: flex;
    width: 72px;
    height: 72px;
    background: #aad63b;
    border-radius: 72px;
    box-shadow: #25313d44 3px 3px 6px 0px inset, #25313d16 -3px -3px 6px 1px inset;
`;

const Text = styled.h4`
    font-family: "Manrope";
    font-weight: 700;
    font-size: 14px;
    line-height: 0%;
    color: #737b8c;
    text-align: end;
    align-items: flex-end;
    padding: 0 5%;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Btn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 327px;
    height: 56px;
    border-radius: 16px;
    background-color: #1238f1;
    border: 0;
    margin-top: 30%;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const BtnTitle = styled.h2`
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope";
    color: #fff;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Div = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Text2 = styled.h2`
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope";
    color: #737b8c;
    font-weight: 0%;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Text3 = styled.a`
    font-size: 16px;
    font-weight: 800;
    font-family: "Manrope";
    color: #25313d;
    font-weight: 0%;
    margin-left: 5px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

// const handleClick = () => {
//   window.scrollTo({ top: 0, behavior: "smooth" });
// };

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string>("");
    const { isLoaded, isAuth } = useAuthContext();
    const returnTo = new URLSearchParams(window.location.search).get("returnTo");
    const [loading, setLoading] = useState<boolean>(false);

    const navigateTo = useCallback(() => {
        if (returnTo) {
            navigate(returnTo);
            return;
        }
        navigate("/");
    }, [navigate, returnTo]);

    if (!isLoaded) {
        return null;
    }

    if (isAuth) {
        navigateTo();
    }

    return (
        <Page>
            <Cont>
                <Frame>
                    <Txt>
                        <Title>Bem vindo de</Title>
                        <Title>Volta</Title>
                    </Txt>
                    <Foto />
                </Frame>
                <Formik
                    initialValues={{ email: "", senha: "" }}
                    onSubmit={async ({ email, senha }) => {
                        try {
                            setLoading(true);
                            await signInWithEmailAndPassword(auth, email, senha);
                            navigateTo();
                        } catch (error) {
                            const { code } = error as { code: string };
                            const message = authMessageByCode[code] || "Erro desconhecido";
                            setError(message);
                            console.error(error);
                        }
                        setLoading(false);
                    }}
                >
                    {({ values: { email, senha }, handleChange, handleSubmit, handleBlur }) => (
                        <>
                            <Frame1>
                                <form onSubmit={handleSubmit}>
                                    <Input placeholder="Email" type="email" value={email} onChange={handleChange("email")} onBlur={handleBlur("email")} />
                                    <Input placeholder="Password" type="password" value={senha} onChange={handleChange("senha")} onBlur={handleBlur("senha")} />
                                    {/* <Text>Esqueceu sua senha?</Text> */}
                                    {error && <Text style={{ color: `red` }}>{error}</Text>}
                                    <Button loading={loading}>
                                        <BtnTitle>Logar</BtnTitle>
                                    </Button>
                                </form>
                            </Frame1>
                        </>
                    )}
                </Formik>
                <Div>
                    <Text2>Não tem uma conta? </Text2>
                    <Text3
                        onClick={() => {
                            navigate("/cadastro");
                        }}
                    >
                        Comece
                    </Text3>
                </Div>
            </Cont>
        </Page>
    );
};

export default LoginPage;
