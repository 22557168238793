import React, { FC } from "react";
import styled from "styled-components";
import useAuthContext from "../../contexts/auth";

const SessinPerfil = styled.div`
    display: flex;
    width: 330px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* padding: 3% 0 0 12%; */
`;

const SessionText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-right: 3%; */
`;

const Foto = styled.div`
    display: flex;
    width: 72px;
    height: 72px;
    background: #aad63b;
    border-radius: 72px;
    box-shadow: #25313d44 3px 3px 6px 0px inset, #25313d16 -3px -3px 6px 1px inset;
    overflow: hidden;
    margin-right: 3%;

    img {
        width: 72px;
        height: 72px;
        border-radius: 72px;
    }
`;

const Title = styled.h1`
    font-size: 30px;
    font-weight: 700;
    font-family: "Manrope";
    line-height: 0%;

    @media screen and (max-width: 480px) {
    }
`;

export interface PerfilElementProps {}

const PerfilElement: FC<PerfilElementProps> = ({}) => {
    const { isAuth, user } = useAuthContext();
    return (
        <SessinPerfil>
            <SessionText>
                <Title>Bom vê-lo aqui</Title>
                <Title>{user?.displayName}</Title>
            </SessionText>
            <Foto>{user?.photoURL && <img src={user?.photoURL} alt="" />}</Foto>
        </SessinPerfil>
    );
};

export default PerfilElement;
