import React from "react";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import NotFoundPage from "./NotFound";
// import { loaderNativeDevice } from "../loader/NativeDevices";
import FirmwaresPage from "./Firmwares/firmwares";
import LoginPage from "./Login";
import Cadastro from "./Cadastro";
import HomePage from "./Home";
import ProjetosPage from "./Projetos";
import CBPage from "./CenaseBotoes";
import CenasPage from "./Cenas";
import PrivateRouter from "../components/privateRouter";
import { ProjectProvider } from "../contexts/projecs/project";
import { DevicesProvider } from "../contexts/devices";
import RootHomeElements from "./Home/rootElements";
import { DeviceProvider } from "../contexts/devices/device";
import { CenesDeviceProvider } from "../contexts/devices/cenas";
import DeviceLocalConfigPage from "./DeviceLocalConfig";
import SingOut from "./singOut";

const Routers = createBrowserRouter(
    [
        {
            path: "",
            errorElement: <NotFoundPage />,
            element: (
                <PrivateRouter>
                    <ProjetosPage />
                </PrivateRouter>
            ),
        },
        {
            path: "projeto",
            // element: <RootElement />,
            // errorElement: <NotFoundPage />,
            children: [
                {
                    path: "",
                    element: <Navigate to="/" replace />,
                },
                {
                    path: ":projectID",
                    element: (
                        <ProjectProvider>
                            <DevicesProvider>
                                <RootHomeElements />
                            </DevicesProvider>
                        </ProjectProvider>
                    ),
                    children: [
                        {
                            path: "",
                            element: <HomePage />,
                        },
                        {
                            path: "cenas/:deviceID",
                            element: (
                                <DeviceProvider>
                                    <Outlet />
                                </DeviceProvider>
                            ),
                            children: [
                                {
                                    path: "",
                                    element: <CBPage />,
                                },
                                {
                                    path: "acoes/:acoesID",
                                    element: (
                                        <CenesDeviceProvider>
                                            <CenasPage />
                                        </CenesDeviceProvider>
                                    ),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "botoes",
                    element: <CBPage />,
                },
                {
                    path: "cenas",
                    element: <CenasPage />,
                },
            ],
        },
        {
            path: "login",
            element: <LoginPage />,
        },
        {
            path: "cadastro",
            element: <Cadastro />,
        },
        {
            path: "device/local/config",
            // loader: loaderNativeDevice,
            element: <DeviceLocalConfigPage />,
        },
        {
            path: "firmwares",
            element: (
                // <PrivateRouter>
                <FirmwaresPage />
                // </PrivateRouter>
            ),
        },
        {
            path: "singOut",
            element: <SingOut />,
        },
    ],
    {}
);

export default Routers;
