import styled, { css } from "styled-components";

const Button = styled.button<{ loading?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 327px;
    height: 56px;
    border-radius: 16px;
    color: #fff;
    background-color: #1238f1;
    border: 0;
    cursor: pointer;

    position: relative;
    ${({ loading }) =>
        !!loading &&
        css`
            ::after {
                content: "";
                position: absolute;
                width: 16px;
                height: 16px;
                top: 0;
                /* left: 0; */
                right: 20px;
                bottom: 0;
                margin: auto;
                border: 4px solid transparent;
                border-top-color: #ffffff;
                border-radius: 50%;
                animation: button-loading-spinner 1s ease infinite;
            }
        `}
    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
    }

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

export default Button;
