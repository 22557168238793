import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import PerfilElement from "../../components/Perfil";
import useProjectsContext from "../../contexts/projecs";
import ModalAddProject from "./addProjecModal";
import { Cont, Div2, DivProjetos, Frame, Num, Page, Projetos, Text2, Title, Txt, Text } from "./style";

const ProjetosPage: React.FC = () => {
    const navigate = useNavigate();

    const { projects } = useProjectsContext();

    // se tiver query 'IP' na url, significa que o usuario esta adicionando um novo dispositivo ao projeto que ele vai selecionar ou criar.
    const [searchParams] = useSearchParams();
    const ip = searchParams.get("ip") || "";

    return (
        <Page>
            <Cont>
                <PerfilElement />
            </Cont>
            <Frame>
                {ip && <Title style={{ margin: `40px 0 ` }}>Selecione um projeto para adicionar o dispositivo</Title>}
            </Frame>
            <Frame>
                <Txt>
                    <Title>Todos os projetos</Title>
                    <Num>({projects.length})</Num>
                </Txt>
                <Div2>
                    <ModalAddProject />
                    {projects.map((project) => (
                        <Projetos
                            key={`pr-${project.id}`}
                            onClick={() =>
                                navigate(`/projeto/${project.id}`, {
                                    state: {
                                        ip,
                                    },
                                })
                            }
                        >
                            <DivProjetos>
                                <Text>{project.name}</Text>
                                <Text2>{project.description}</Text2>
                            </DivProjetos>
                        </Projetos>
                    ))}
                </Div2>
            </Frame>
        </Page>
    );
};

export default ProjetosPage;
