import axios from "axios";
import { Device } from "../models/FB/device";
import EventEmitter from "events";

export const getACCTList = (ip: string) => {
    return axios<Array<string>>(`http://${ip}/acct`).then((d) => {
        return d.data;
    });
};

interface ActionResponse<T = any> {
    code: string;
    data: T;
}

export class DeviceNative extends EventEmitter {
    readonly dev: Device;
    private _acctListPaths: Array<string> = [];
    private _acctList: Array<DeviceActionDefine> = [];
    private _isOnline: boolean = false;
    private cancelTokenSource = axios.CancelToken.source();

    private set isOnline(value: boolean) {
        this._isOnline = value;
    }

    public get isOnline(): boolean {
        return this._isOnline;
    }

    private set acctListPaths(value: Array<string>) {

        this._acctListPaths = value;
        this.getACCTDefines();
    }

    public get acctListPaths(): Array<string> {
        return this._acctListPaths;
    }

    private set acctList(value: Array<DeviceActionDefine>) {
        this._acctList = [...value];
        this.emit('acctList', value);
        this.emit('cenes', this.listOfCenes());
        this.emit('actions', this.listOfActions());
    }

    public get acctList(): Array<DeviceActionDefine> {
        return this._acctList;
    }

    public listOfCenes(): Array<DeviceActionDefine> {
        return this.acctList.filter((d) => /cs/.test(d.path)).sort((a, b) => a.name.localeCompare(b.name));
    }

    public listOfActions(): Array<DeviceActionDefine> {
        return this.acctList.filter((d) => /ac/.test(d.path));
    }

    constructor(dev: Device) {
        super();
        this.dev = dev;

        this.getACCTList().then((list) => {
            this.isOnline = true;
        });

    }

    public getACCTList() {
        return getACCTList(this.dev.ip).then((list) => {
            this.acctListPaths = list;
            return list;
        });
    }

    public async getACCTDefine(path: string, cancelToken: any) {
        return axios.get<DeviceActionDefine>(`http://${this.dev.ip}${path}`, { cancelToken: cancelToken }).then((d) => {
            return d.data;
        });
    }

    // para coletar os defines de cada acct list deve ser sequencial pois o device não suporta multiplas requisições simultaneas
    private async getACCTDefines() {
        this.cancelTokenSource.cancel('Operation canceled by the user.');
        this.cancelTokenSource = axios.CancelToken.source();
        for (let i = 0; i < this.acctListPaths.length; i++) {
            const path = this.acctListPaths[i];
            const acct = await this.getACCTDefine(path, this.cancelTokenSource.token);
            this.acctList = [...this.acctList, acct];
        }
        return this.acctList;
    }

    public async updateACCTByPath<T = any>(path: string, data: any) {
        const query = Object.keys(data).map((k) => `${k}=${data[k]}`).join('&');
        return axios.request<ActionResponse<T>>({
            url: `http://${this.dev.ip}${path}?rJson${!!query ? `&${query}` : ''}`,
            method: 'GET',
        });
    }

    public async getACCTByPath<T = any>(path: string, n?: number) {
        return axios.request<ActionResponse<T>>({
            url: `http://${this.dev.ip}${path}?rJson${n !== undefined ? `&$n=${n}` : ''}`,
            method: 'GET',
        });

    }

    public async deleteACCTByPath<T = any>(path: string, n?: number) {
        return axios.request<ActionResponse<T>>({
            url: `http://${this.dev.ip}${path}?rJson${n !== undefined ? `&$n=${n}` : ''}`,
            method: 'DELETE',

        });

    }
}

/*
{
    "name": "bt0",
    "path": "/cs/bt0",
    "params": [
        {
            "name": "id",
            "params": [
                "$inc=[1,20]",
                "$dec=[1,20]",
                "$max=65535",
                "$min=0"
            ],
            "type": "Integer"
        },
        {
            "name": "host",
            "params": [
                "$max=32"
            ],
            "type": "String"
        },
        {
            "name": "path",
            "params": [
                "$max=32"
            ],
            "type": "String"
        },
        {
            "name": "query",
            "params": [
                "$max=512"
            ],
            "type": "String"
        },
        {
            "name": "invertToOff",
            "params": [
                "toggle",
                "on",
                "off",
                "false",
                "true"
            ],
            "type": "Boolean"
        }
    ]
*/

export interface DeviceActionDefineParam {
    name: string;
    params: Array<string>;
    type: string;
}

export interface DeviceActionDefine {
    name: string;
    path: string;
    params: Array<DeviceActionDefineParam>;
    paramsResponse?: Array<DeviceActionDefineParam>;
}