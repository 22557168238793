import axios, { CancelToken } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";

interface useLocalizeDevicesProps {
    initialIpDevice: string;
}

export interface deviceInfoLocalize {
    ip: string;
    model: string;
    deviceName: string;
    version: string;
    serialNumber: string;
    type: string;
    id: string;
    date: string;
}
export const useLocalizeDevices = ({ initialIpDevice }: useLocalizeDevicesProps) => {
    const [loading, setLoading] = useState(false);
    const [devicesLocalizes, setDevicesLocalizes] = useState<deviceInfoLocalize[]>([]);
    const ips = useRef<string[]>([]);

    const fetchDeviceInfo = async (ip: string) => {
        if (!ip) throw new Error("ip is required");
        const res = await axios(`http://${ip}/if/device?rJson`);
        const { data } = await res.data;
        return data;
    };

    const fetchDeviceLocalize = useCallback(async (ip: string, signal?: CancelToken) => {
        if (!ip) throw new Error("ip is required");
        const res = await axios(`http://${ip}/cl/bonjour?protocol=udp&service=nd&timeout=2000&rJson`, {
            timeout: 20000,
            cancelToken: signal ? signal : undefined,
        });
        const { data } = await res.data;
        return data;
    }, []);

    const localizeAll = useCallback(
        async (ip: string, cancelToken?: CancelToken) => {
            try {
                const data = await fetchDeviceLocalize(ip, cancelToken);
                if (data) {
                    try {
                        const { deviceName, model, serialNumber, version, type, id, date } = await fetchDeviceInfo(ip);
                        setDevicesLocalizes((prev) =>
                            [
                                ...prev,
                                {
                                    ip, deviceName, model, serialNumber, version, type, id, date
                                },
                            ].filter((device, index, self) => self.findIndex((d) => d.ip === device.ip) === index)
                        );
                    } catch (error) {}

                    const { devices } = data as { devices: string };
                    const listIps = devices.split(",").map((ip) => ip.split(":")[0]);
                    listIps.push(ip);
                    const newIps = listIps.filter((ip) => !ips.current.includes(ip));
                    ips.current = [...ips.current, ...newIps];
                    await Promise.all(newIps.map((ip) => localizeAll(ip)));
                }
            } catch (error) {
                console.log({ error });
            }
        },
        [fetchDeviceLocalize]
    );

    const localizeAllDevices = useCallback(
        async (cancelToken?: CancelToken) => {
            if (initialIpDevice) {
                try {
                    ips.current = [initialIpDevice];
                    setLoading(true);
                    await localizeAll(initialIpDevice, cancelToken);
                    setLoading(false);
                } catch (error) {
                    console.log({ error });
                }
            }
        },
        [initialIpDevice, localizeAll]
    );


    return { devicesLocalizes, localizeAllDevices, loading };
};
