import React from "react";
import { Navigate } from "react-router-dom";
import useAuthContext from "../../contexts/auth";

interface PrivateRouterProps {
    children?: any;
}

const PrivateRouter: React.FC<PrivateRouterProps> = ({ children }) => {
    const { isAuth, isLoaded } = useAuthContext();
    if (!isLoaded) {
        return <div>carregando...</div>;
    }
    let returnTo = window.location.pathname + window.location.search;
    if (!isAuth) {
        return <Navigate to={`/login?returnTo=${encodeURI(returnTo)}`} replace />;
    }
    return children;
};

export default PrivateRouter;
