import React, { FC } from "react";
import { deviceInfoLocalize } from "../../../hooks/localizeDevices";

export interface ListOfAddDevicesProps {
    devices: deviceInfoLocalize[];
}

const ListOfAddDevices: FC<ListOfAddDevicesProps> = ({ devices }) => {
    return (
        <>
            {
                devices.map((device) => {
                    return (
                        <div key={`device_localized_${device.ip}`} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 0" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {/* <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: device.status === "online" ? "green" : "red", marginRight: "10px" }}></div> */}
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span>{device.deviceName}</span>
                                    <span>{device.model}</span>
                                    <span>{device.ip}</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ marginRight: "10px" }}>{device.id}</span>
                                <span style={{ marginRight: "10px" }}>{device.version}</span>
                                <span style={{ marginRight: "10px" }}>{device.date}</span>
                            </div>
                        </div>
                    );
                })
            }
        </>
    );
};

export default ListOfAddDevices;
