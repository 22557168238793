import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import KeypadS from "../../assets/KeypadS.svg";
import Keypadq from "../../assets/Keypadq.svg";
import Modulo from "../../assets/Modulo.svg";
import Setab from "../../assets/Setab.png";
import Back from "../../assets/Back.png";
import Edit from "../../assets/Edit.png";
import useDeviceContext from "../../contexts/devices/device";

const Page = styled.div`
    display: flex;
    height: 100vh;
`;

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    flex: 1;
    min-width: 268px;
    min-height: 100%;
    background: #eff3f6;
`;

const Frame1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 1100px;
    height: 100%;
    align-items: flex-start;
    justify-content: start;
    padding-top: 3%;
`;

const Frame2 = styled.div`
    display: flex;
    flex: 1;
    min-width: 100px;
    min-height: 100%;
`;

// const Frame = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: flex-start;
//     width: 268px;
//     min-height: 100%;
//     background: #EFF3F6;
// `;

// const Frame1 = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     justify-content: start;
//     padding-top: 3%;
//     width: 85%;
// `;

const Title = styled.h1`
    font-size: 30px;
    font-weight: 700;
    font-family: "Manrope";
    line-height: 0%;

    @media screen and (max-width: 480px) {
        /* width: 350px; */
    }
`;

const Num = styled.h3`
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope";
    line-height: 0%;

    @media screen and (max-width: 480px) {
        /* width: 350px; */
    }
`;

const Txt = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 15% 0 6%;
    /* padding: 0 20%; */
`;

const Disp = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 249px;
    height: 58px;
    background: #eff3f6;
    /* border-radius: 16px; */
    /* margin: 4% 0; */
    box-shadow: #25313d44 3px 3px 6px 0px inset, #25313d16 -3px -3px 6px 1px inset;
    border: 0;
    cursor: pointer;
`;

const Config = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 191px;
    height: 26px;
    border-radius: 16px;
    border-color: #505edc;
    background-color: #cfdbe0;
    margin-bottom: 10px;
    cursor: pointer;
    font-family: "Manrope";
    font-weight: 700;
    font-size: 14px;
    line-height: 0%;
    color: #505edc;

    :hover {
        background: #505edc;
        border-color: transparent;
        color: #fff;
        /* color: #77A9D6; */
        /* transform: translateY(-0.25em); */
        /* border-bottom: 0.2vw solid #77A9D6; */
    }
`;

const Text = styled.h2`
    font-family: "Manrope";
    font-weight: 700;
    font-size: 16px;
    line-height: 0%;
    color: #1b242d;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Text2 = styled.h2`
    font-size: 16px;
    font-weight: 500;
    font-family: "Manrope";
    color: #1b242d;
    /* line-height: 0%; */

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Div1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-right: 3%; */
    /* background: red; */
`;

const Div2 = styled.div`
    display: flex;
    width: 930px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 5%;
`;

const Div3 = styled.div`
    display: grid;
    width: 100%;
    padding: 3% 0;
    gap: 20px;
`;

const Text3 = styled.h1`
    font-size: 14px;
    font-weight: 600;
    font-family: "Manrope";
    color: #737b8c;
    /* margin: -1% 0 -5%; */
    /* font-weight: 0%; */

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Opcoes = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5% 0 3%;
    width: 800px;
    height: 100px;
    background: #eff3f6;
    border-radius: 16px;
    border: 0;
    /* box-shadow: #25313d44 3px 4px 3px 0px inset, #25313d16 -1px -1px 3px 1px inset; */
    /* cursor: pointer; */
`;

const BtnName = styled.input`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 285px;
    height: 50px;
    border-radius: 16px;
    border: 0;
    outline: 0 none;
    box-shadow: #25313d44 3px 4px 3px 0px inset, #25313d16 -1px -1px 3px 1px inset;
    padding-left: 15px;
`;

const CBPage: React.FC = () => {
    const navigate = useNavigate();
    const { device } = useDeviceContext();
    const [activeButton, setActiveButton] = useState("");

    const listOfCenes = device?.controller.listOfCenes();

    console.log(device?.controller.listOfCenes());

    return (
        <Page>
            <Frame1>
                <Div2>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "280px" }}>
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("..");
                            }}
                            src={Back}
                            alt=""
                        />
                        <Title>Cenas / Botões</Title>
                    </div>
                    <Div3 style={{ overflow: `scroll`}}>
                        {listOfCenes?.map((cene) => {
                            return (
                                <Opcoes key={`cene-${cene.name}-of-${device?.data.id}`}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "420px" }}>
                                        <Text2>{cene.name}</Text2>
                                        {/* <Div1 style={{ padding: "0 4%", marginBottom: "2%" }}>
                                            <Text3>Nome do botão</Text3>
                                            <BtnName placeholder="Btn" />
                                        </Div1> */}
                                    </div>
                                    <img
                                        style={{ cursor: "pointer" }}
                                        src={Edit}
                                        alt=""
                                        onClick={() => {
                                            navigate(`acoes/${cene.name}`);
                                        }}
                                    />
                                </Opcoes>
                            );
                        })}
                    </Div3>
                </Div2>
            </Frame1>
        </Page>
    );
};

export default CBPage;
