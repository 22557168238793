import React, { useState } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import styled from "styled-components";
import Mornin from "../../assets/Mornin.png";
import Night from "../../assets/Night.png";
import Music from "../../assets/Music.png";
import Movie from "../../assets/Movie.png";
import Leave from "../../assets/Leave.png";
import Clima from "../../assets/Clima.svg";
import Vent from "../../assets/Vent.svg";
import Switch from "../../components/toggleswitch/Switch";
import AddDevicesFromIP from "./addDevices";
import useProjectContext from "../../contexts/projecs/project";

const Frame1 = styled.div`
    display: flex;
    flex-direction: column;
    /* flex: 1; */
    width: "100%";
    max-width: 1400px;
    padding: 10% 5% 0 5%;
    /* width: 1100px; */
    /* height: 100%; */
    align-items: flex-start;
    justify-content: start;
    /* width: 327.5px; */
    /* height: 128px; */
    /* margin-top: 6%; */
    /* background: red; */
    padding-top: 3%;
`;

const Title = styled.h1`
    font-size: 30px;
    font-weight: 700;
    font-family: "Manrope";
    line-height: 0%;

    @media screen and (max-width: 480px) {
        /* width: 350px; */
    }
`;

const Text = styled.h2`
    font-family: "Manrope";
    font-weight: 700;
    font-size: 16px;
    line-height: 0%;
    color: #1b242d;
    /* text-align: end;
    align-items: flex-end; */
    /* padding: 0 5%; */

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Text2 = styled.h3`
    font-size: 12px;
    font-weight: 400;
    font-family: "Manrope";
    color: #1b242d;
    line-height: 0%;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Div2 = styled.div`
    display: flex;
    width: 930px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 5%;
    /* background: red; */
`;

const Div3 = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 3% 0;
    gap: 2%;
    /* background: red; */
`;

const Cenas = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 168px;
    height: 80px;
    background: #737b8c;
    border-radius: 16px;
    gap: 15%;
    cursor: pointer;
    /* padding: 0 10%; */
    /* margin: 4% 0; */
    /* box-shadow: #25313d44 3px 3px 6px 0px inset, #25313d16 -3px -3px 6px 1px inset; */
`;

const DivCena = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 3%;
    height: 56px;
    /* background: red; */
`;

const Text3 = styled.h1`
    font-size: 12px;
    font-weight: 300;
    font-family: "Manrope";
    color: #eff3f6;
    margin: -1% 0 -5%;
    /* font-weight: 0%; */

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Dispositivos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 155px;
    height: 155px;
    background: #737b8c;
    border-radius: 24px;
    gap: 1%;

    /* gap: 15%; */
    /* padding: 0 10%; */
    /* margin: 4% 0; */
`;

const HomePage: React.FC = () => {
    const [value, setValue] = useState(false);
    const [selected, setSelected] = useState("");
    const { project } = useProjectContext();

    const handlePress = (button: any) => {
        setSelected(button);
    };

    return (
        <>
            <AddDevicesFromIP projectID={project?.id} />
            <Frame1 style={{ position: "relative", filter: "blur(10px)" }}>
                <div
                    style={{
                        position: "absolute",
                        background: "black",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        filter: "blur(10px)",
                        right: 0,
                        zIndex: 0,
                        opacity: 0.03,
                    }}
                ></div>
                <Div2>
                    <Title>Cenas</Title>
                    <Div3>
                        <Cenas
                            style={
                                selected === "Mornin"
                                    ? {
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "168px",
                                          height: "88px",
                                          background: "#FFFFFF",
                                          borderRadius: "16px",
                                          gap: "15%",
                                          boxShadow: "#0000001a 3px 3px 6px 0px, #9fb3c714 -3px -3px 6px 1px",
                                      }
                                    : {}
                            }
                            onClick={() => handlePress("Mornin")}
                        >
                            <img src={Mornin} alt="" />
                            <DivCena>
                                <Text style={selected === "Mornin" ? {} : { color: "#FFFFFF" }}>Mornin'</Text>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Text3 style={selected === "Mornin" ? { color: "#737B8C" } : {}}>5 devices</Text3>
                                    <Text3 style={selected === "Mornin" ? { color: "#737B8C", marginLeft: "2px" } : { display: "none" }}>is</Text3>
                                </div>
                                <Text2 style={selected === "Mornin" ? { color: "#FF8A5D", fontWeight: "700" } : { display: "none" }}>on</Text2>
                            </DivCena>
                        </Cenas>
                        <Cenas
                            style={
                                selected === "Night"
                                    ? {
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "168px",
                                          height: "88px",
                                          background: "#FFFFFF",
                                          borderRadius: "16px",
                                          gap: "15%",
                                          boxShadow: "#0000001a 3px 3px 6px 0px, #9fb3c714 -3px -3px 6px 1px",
                                      }
                                    : {}
                            }
                            onClick={() => handlePress("Night")}
                        >
                            <img src={Night} alt="" />
                            <DivCena>
                                <Text style={selected === "Night" ? {} : { color: "#FFFFFF" }}>Night</Text>
                                <div style={{ display: "flex" }}>
                                    <Text3 style={selected === "Night" ? { color: "#737B8C" } : {}}>5 devices</Text3>
                                    <Text3 style={selected === "Night" ? { color: "#737B8C", paddingLeft: "2px" } : { display: "none" }}>is</Text3>
                                </div>
                                <Text2 style={selected === "Night" ? { color: "#FF8A5D", fontWeight: "700" } : { display: "none" }}>on</Text2>
                            </DivCena>
                        </Cenas>
                        <Cenas
                            style={
                                selected === "Music"
                                    ? {
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "168px",
                                          height: "88px",
                                          background: "#FFFFFF",
                                          borderRadius: "16px",
                                          gap: "15%",
                                          boxShadow: "#0000001a 3px 3px 6px 0px, #9fb3c714 -3px -3px 6px 1px",
                                      }
                                    : {}
                            }
                            onClick={() => handlePress("Music")}
                        >
                            <img src={Music} alt="" />
                            <DivCena>
                                <Text style={selected === "Music" ? {} : { color: "#FFFFFF" }}>Music</Text>
                                <div style={{ display: "flex" }}>
                                    <Text3 style={selected === "Music" ? { color: "#737B8C" } : {}}>5 devices</Text3>
                                    <Text3 style={selected === "Music" ? { color: "#737B8C", paddingLeft: "2px" } : { display: "none" }}>is</Text3>
                                </div>
                                <Text2 style={selected === "Music" ? { color: "#FF8A5D", fontWeight: "700" } : { display: "none" }}>on</Text2>
                            </DivCena>
                        </Cenas>
                        <Cenas
                            style={
                                selected === "Movie"
                                    ? {
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "168px",
                                          height: "88px",
                                          background: "#FFFFFF",
                                          borderRadius: "16px",
                                          gap: "15%",
                                          boxShadow: "#0000001a 3px 3px 6px 0px, #9fb3c714 -3px -3px 6px 1px",
                                      }
                                    : {}
                            }
                            onClick={() => handlePress("Movie")}
                        >
                            <img src={Movie} alt="" />
                            <DivCena>
                                <Text style={selected === "Movie" ? {} : { color: "#FFFFFF" }}>Movie</Text>
                                <div style={{ display: "flex" }}>
                                    <Text3 style={selected === "Movie" ? { color: "#737B8C" } : {}}>5 devices</Text3>
                                    <Text3 style={selected === "Movie" ? { color: "#737B8C", paddingLeft: "2px" } : { display: "none" }}>is</Text3>
                                </div>
                                <Text2 style={selected === "Movie" ? { color: "#FF8A5D", fontWeight: "700" } : { display: "none" }}>on</Text2>
                            </DivCena>
                        </Cenas>
                        <Cenas
                            style={
                                selected === "Leave"
                                    ? {
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "168px",
                                          height: "88px",
                                          background: "#FFFFFF",
                                          borderRadius: "16px",
                                          gap: "15%",
                                          boxShadow: "#0000001a 3px 3px 6px 0px, #9fb3c714 -3px -3px 6px 1px",
                                      }
                                    : {}
                            }
                            onClick={() => handlePress("Leave")}
                        >
                            <img src={Leave} alt="" />
                            <DivCena>
                                <Text style={selected === "Leave" ? {} : { color: "#FFFFFF" }}>Leave</Text>
                                <div style={{ display: "flex" }}>
                                    <Text3 style={selected === "Leave" ? { color: "#737B8C" } : {}}>5 devices</Text3>
                                    <Text3 style={selected === "Leave" ? { color: "#737B8C", paddingLeft: "2px" } : { display: "none" }}>is</Text3>
                                </div>
                                <Text2 style={selected === "Leave" ? { color: "#FF8A5D", fontWeight: "700" } : { display: "none" }}>on</Text2>
                            </DivCena>
                        </Cenas>
                    </Div3>
                </Div2>
                <Div2>
                    <Title>Dispositivos</Title>
                    <Div3>
                        {/* <DispositivoOn>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between',width: '123px', margin: '10% 0 5%'}}>
                                <Text>ON</Text>
                                <Switch isOn={value} handleToggle={() => {setValue(!value)}}/>
                            </div>
                            <img src={Clima} alt="" />
                            <Text style={{fontSize: '14px'}}>Clima</Text>
                            <Text3 style={{color: '#737B8C'}}>17°C</Text3>
                        </DispositivoOn> */}
                        <Dispositivos
                            style={
                                value === true
                                    ? {
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          width: "155px",
                                          height: "155px",
                                          background: "#FFFFFF",
                                          borderRadius: "24px",
                                          boxShadow: "#0000001a 3px 3px 6px 0px, #9fb3c714 -3px -3px 6px 1px",
                                          gap: "1%",
                                      }
                                    : {}
                            }
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "123px", margin: "10% 0 5%" }}>
                                <Text style={value === true ? { display: "none" } : { color: "#FFFFFF" }}>OFF</Text>
                                <Text style={value === true ? {} : { display: "none" }}>ON</Text>
                                {/* <Toggle style={{background: '#314252'}}></Toggle> */}
                                {/* <ToggleSwitch/> */}
                                <Switch
                                    isOn={value}
                                    handleToggle={() => {
                                        setValue(!value);
                                    }}
                                />
                            </div>
                            <img src={Clima} alt="" />
                            <Text style={value === true ? { fontSize: "14px" } : { fontSize: "14px", color: "#FFFFFF" }}>Clima</Text>
                            <Text3 style={value === true ? { color: "#737B8C" } : { display: "none" }}>17°C</Text3>
                        </Dispositivos>
                        <Dispositivos
                            style={
                                value === true
                                    ? {
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          width: "155px",
                                          height: "155px",
                                          background: "#FFFFFF",
                                          borderRadius: "24px",
                                          boxShadow: "#0000001a 3px 3px 6px 0px, #9fb3c714 -3px -3px 6px 1px",
                                          gap: "1%",
                                      }
                                    : {}
                            }
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "123px", margin: "10% 0 5%" }}>
                                <Text style={value === true ? { display: "none" } : { color: "#FFFFFF" }}>OFF</Text>
                                <Text style={value === true ? {} : { display: "none" }}>ON</Text>
                                {/* <Toggle style={{background: '#314252'}}></Toggle> */}
                                {/* <ToggleSwitch/> */}
                                <Switch
                                    isOn={value}
                                    handleToggle={() => {
                                        setValue(!value);
                                    }}
                                />
                            </div>
                            <img src={Vent} alt="" />
                            <Text style={value === true ? { fontSize: "14px" } : { fontSize: "14px", color: "#FFFFFF" }}>Ventiladores</Text>
                            <Text3 style={value === true ? { color: "#737B8C" } : { display: "none" }}>17°C</Text3>
                        </Dispositivos>
                    </Div3>
                </Div2>
            </Frame1>
        </>
    );
};

export default HomePage;
