import React, { useCallback, useEffect, useState } from "react";
import createCTX from "../../utils/createCTX";
import { useParams } from "react-router-dom";
import useDevicesContext, { DevicesProviderProps } from ".";
import { Device } from "../../models/FB/device";

interface DeviceInterface {
    device?: DevicesProviderProps['devices'][number];
}

const [useDeviceContext, DeviceContext] = createCTX<DeviceInterface>();

const DeviceProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { devices } = useDevicesContext();

    //get :id from url react router dom
    const { deviceID } = useParams<{ deviceID: string }>();

    const value: DeviceInterface = {
        device: devices.find((p) => p.data.id === deviceID),
    };

    return <DeviceContext value={value}>{children}</DeviceContext>;
};

export default useDeviceContext;
export { DeviceProvider };
