import React, { FC, useState } from "react";
import { DivProjetos, Frame, Page, Projetos, Text, Title, Txt } from "./style";
import Modal from "react-modal";
import { Formik } from "formik";
import Input from "../../components/input";
import Button from "../../components/buttom";
import useProjectsContext from "../../contexts/projecs";

export interface ModalAddProjectProps {}

const ModalAddProject: FC<ModalAddProjectProps> = ({}) => {
    const [open, setOpen] = useState(false);
    const { projects, createNewProject } = useProjectsContext();

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    return (
        <>
            <Projetos style={{ backgroundColor: "#b9b9b9" }} onClick={handleOpen}>
                <DivProjetos>
                    <Text>Adicionar novo projeto</Text>
                </DivProjetos>
            </Projetos>
            <Modal isOpen={open} onRequestClose={handleClose}>
                <Page style={{ height: "100%" }}>
                    <Frame style={{ display: "flex", height: "100%", justifyContent: "space-around", alignItems: "center" }}>
                        <Title>Adicionar novo projeto</Title>
                        <Formik
                            initialValues={{ name: "", description: "" }}
                            onSubmit={({ description, name }) => {
                                createNewProject({ description, name });
                                handleClose();
                            }}
                        >
                            {({ values, handleChange, handleSubmit }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Text>Nome do projeto</Text>
                                        <Input type="text" name="name" placeholder="Nome do projeto" value={values.name} onChange={handleChange} />
                                        <Text>Descrição do projeto</Text>
                                        <Input
                                            type="text"
                                            name="description"
                                            placeholder="Descrição do projeto"
                                            value={values.description}
                                            onChange={handleChange}
                                        />
                                        <Button type="submit">Criar</Button>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Frame>
                </Page>
            </Modal>
        </>
    );
};

export default ModalAddProject;
