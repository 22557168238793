import React, { FC } from "react";
import esptools from "esptool-js";

export interface FirmwaresPageProps {}

const FirmwaresPage: FC<FirmwaresPageProps> = ({}) => {
    
    return (
        <>
            <h1>FirmwaresPage</h1>
        </>
    );
};

export default FirmwaresPage;
