import React, { FC, useEffect, useRef, useState } from "react";
import createCTX from "../../utils/createCTX";
import { Device, deviceQuerys } from "../../models/FB/device";
import useProjectContext from "../projecs/project";
import { onSnapshot } from "firebase/firestore";
import useAuthContext from "../auth";
import { DeviceNative } from "../../controllers/NativeDevice";

export interface DevicesProviderProps {
    devices: {
        data: Device;
        controller: DeviceNative;
    }[];
}

const [useDevicesContext, DevicesProviderContext] = createCTX<DevicesProviderProps>();

const DevicesProvider: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { project } = useProjectContext();
    const { user } = useAuthContext();
    const [projects, setProjects] = useState<Device[]>([]);
    // const [controllers, setControllers] = useState<DeviceNative[]>([]);
    const controllers = useRef<DeviceNative[]>([]); // controladores dever serem refs pois nao podem ser recriados

    useEffect(() => {
        if (project?.id && user?.uid) {
            onSnapshot(deviceQuerys.byUserAndProjectID(user.uid, project.id), (snapshot) => {
                const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Device));

                console.log(data);
                const newProjects = data.filter((device) => !controllers.current.find((oldDevice) => oldDevice.dev.id === device.id));
                newProjects.forEach((device) => {
                    controllers.current.push(new DeviceNative(device));
                });

                setProjects(data);
            });
        }
    }, [project?.id, user?.uid]);

    const values: DevicesProviderProps = {
        devices: projects.map((data) => ({
            data,
            controller: controllers.current.find((controller) => controller.dev.id === data.id) as DeviceNative,
        })),
    };

    return <DevicesProviderContext value={values}>{children}</DevicesProviderContext>;
};

export default useDevicesContext;
export { DevicesProvider };
