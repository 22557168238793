// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore, serverTimestamp } from "firebase/firestore";
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCZhXjyLATUn0PcId8_gfEaD9kWQaJenKs",
    authDomain: "smart-home-control-pro.firebaseapp.com",
    databaseURL: "https://smart-home-control-pro-default-rtdb.firebaseio.com",
    projectId: "smart-home-control-pro",
    storageBucket: "smart-home-control-pro.appspot.com",
    messagingSenderId: "432329693064",
    appId: "1:432329693064:web:52c7d696d6ff6ebb060c3d",
    measurementId: "G-BNKWH49GWT",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const performance = getPerformance(app);
export const updated_at_timestamp = serverTimestamp();

// if (process.env.NODE_ENV === "development" && process.env.REACT_APP_EMULATOR_HOST) {
//     connectFirestoreEmulator(firestore, process.env.REACT_APP_EMULATOR_HOST, 8080);
//     connectAuthEmulator(auth, `http://${process.env.REACT_APP_EMULATOR_HOST}:9099`);
// }
