import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Back from "../../assets/Back.png";
import useCenesDeviceContext from "../../contexts/devices/cenas";
import useDevicesContext from "../../contexts/devices";
import OptionsCenas from "./Opcoes";

const Page = styled.div`
    display: flex;
    height: 100vh;
`;

const Frame1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 1100px;
    align-items: flex-start;
    justify-content: start;
    padding-top: 3%;
`;

const Frame2 = styled.div`
    display: flex;
    flex: 1;
    min-width: 100px;
    min-height: 100%;
`;

const Title = styled.h1`
    font-size: 30px;
    font-weight: 700;
    font-family: "Manrope";
    line-height: 0%;

    @media screen and (max-width: 480px) {
        /* width: 350px; */
    }
`;

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 800px;
`;

const AddCena = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    width: 150px;
    height: 50px;
    background: #1238f1;
    color: #ffffff;
    border-radius: 16px;
    border: 0;
    cursor: pointer;
    margin-top: 5%;
`;

const Div2 = styled.div`
    display: flex;
    width: 930px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 5%;
    /* background: red; */
`;

const Div3 = styled.div`
    display: grid;
    /* flex-direction: column; */
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 3% 0;
    gap: 20px;
`;

const CenasPage: React.FC = () => {
    const navigate = useNavigate();
    const { cenesdevice, deleteLastCene, addCene, updateCene } = useCenesDeviceContext();
    const { devices } = useDevicesContext();
    const ids = devices?.map(({ data }) => data.deviceID);

    return (
        <Page>
            <Frame1>
                <Div2>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "140px" }}>
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("..");
                            }}
                            src={Back}
                            alt=""
                        />
                        <Title>Cenas</Title>
                    </div>
                    <Div3>
                        {cenesdevice?.map(({ id, invertToOff, path, query }, i) => (
                            <OptionsCenas
                                key={`OptionsCenes-${i}`}
                                ids={ids}
                                {...{ id, invertToOff, path, query }}
                                onChange={(e) => {
                                    updateCene(e, i);
                                }}
                            />
                        ))}
                        <Div>
                            <div style={{ width: "400px", justifyContent: "space-around", display: "flex" }}>
                                <AddCena
                                    onClick={() => {
                                        if (window.confirm("Deseja realmente excluir a última cena?")) {
                                            deleteLastCene();
                                        }
                                    }}
                                >
                                    -
                                </AddCena>
                                <AddCena onClick={addCene}>+</AddCena>
                            </div>
                        </Div>
                    </Div3>
                </Div2>
            </Frame1>
            <Frame2 />
        </Page>
    );
};

export default CenasPage;
