import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DownB from "../../assets/DownB.png";
import DropDown from "../../components/dropdown";

export const Btn = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    height: 45px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: #25313d44 3px 4px 3px 0px inset, #25313d16 -1px -1px 3px 1px inset;
    border: 0;
    cursor: pointer;
    position: absolute;
    padding: 0 1%;

    :hover {
        background-color: #eff3f6;
    }
    .active {
        background-color: #f2f7fd;
    }
`;

export const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    height: 45px;
    border: 0;
    cursor: pointer;
    padding: 0 1%;
`;

const DropD = <img src={DownB} alt=" " />;

interface DropdownProps {
    onChange?: (idSelected: string) => void;
    placeholder?: string;
    opts: string[];
    value?: string | undefined;
}

const MenuParamsCene: React.FC<DropdownProps> = ({ onChange, value, opts, placeholder }): JSX.Element => {
    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const [selectOpcao, setSelectOpcao] = useState<string>("");

    useEffect(() => {
        setSelectOpcao(value || "");
    }, [value]);

    /**
     * Toggle the drop down menu
     */
    const toggleDropDown = () => {
        setShowDropDown(!showDropDown);
    };

    /**
     * Hide the drop down menu if click occurs
     * outside of the drop-down element.
     *
     * @param event  The mouse event
     */
    const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
        if (event.currentTarget === event.target) {
            setShowDropDown(false);
        }
    };

    /**
     * Callback function to consume the
     * estado name from the child component
     *
     * @param estado  The selected estado
     */
    const OpcaoSelection = (idSelected: string): void => {
        setSelectOpcao(idSelected);
        onChange?.(idSelected);
    };

    return (
        <>
            <Div>
                <Btn
                    // className={showDropDown ? "active" : undefined}
                    onClick={(): void => toggleDropDown()}
                    onBlur={(e: React.FocusEvent<HTMLButtonElement>): void => dismissHandler(e)}
                >
                    <div>{selectOpcao || placeholder} </div>
                    {DropD}
                    {showDropDown && (
                        <DropDown opcoes={opts} showDropDown={false} toggleDropDown={(): void => toggleDropDown()} opcaoSelection={OpcaoSelection} />
                    )}
                </Btn>
            </Div>
        </>
    );
};

export default MenuParamsCene;
