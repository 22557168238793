import { useState, ChangeEvent, useCallback } from "react";
import styled from "styled-components";

const StyledLabel = styled.label<{ checked: boolean }>`
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 30px;
    border-radius: 43px;
    background: ${({ checked }) => (checked ? "#AAD63B" : "#314252")};
    display: block;
    /* border-radius: 100px;   */
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: ${({ checked }) => (checked ? "23px" : "calc(18% - 6px)")};
        top: 3px;
        width: 24px;
        height: 24px;
        background: #fff;
        border-radius: 43px;
        transition: 0.3s;
    }
`;

export interface TooglerProps {
    value?: boolean;
    onChange?: (index: boolean) => void;
}

const ToggleSwitch: React.FC<TooglerProps> = ({ onChange, value }) => {
    const [active, setActive] = useState(!!value);

    const handleOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setActive(!active);
            onChange?.(e.target.checked);
        },
        [active, onChange]
    );

    return (
        <StyledLabel checked={active}>
            <input type="checkbox" checked={active} onChange={handleOnChange} />
        </StyledLabel>
    );
};

export default ToggleSwitch;
