import styled from "styled-components";

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
`;

const Cont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    height: 275px;
    background: #eff3f6;
    padding: 20px;
    /* position: absolute; */
    top: 0;
`;

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 948px;
    margin-bottom: 4%;
`;

const Title = styled.h1`
    font-size: 30px;
    font-weight: 700;
    font-family: "Manrope";
    line-height: 0%;

    @media screen and (max-width: 480px) {
    }
`;

const Num = styled.h3`
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope";
    line-height: 0%;

    @media screen and (max-width: 480px) {
        /* width: 350px; */
    }
`;

const Txt = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 0 0 2%;
    /* padding: 0 20%; */
`;

const Projetos = styled.div`
    display: flex;
    align-items: flex-end;
    width: 299px;
    height: 203px;
    background: #c79d13;
    border-radius: 16px;
    margin: 0 0 2.5%;

    :hover {
        cursor: pointer;
    }
`;

const DivProjetos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 299px;
    height: 92px;
    background: #dbdfdb;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 0 10%;
`;

const Text = styled.h2`
    font-family: "Manrope";
    font-weight: 700;
    font-size: 16px;
    line-height: 0%;
    color: #1b242d;

    @media screen and (max-width: 480px) {
    }
`;

const Text2 = styled.h3`
    font-size: 12px;
    font-weight: 400;
    font-family: "Manrope";
    color: #1b242d;
    line-height: 0%;

    @media screen and (max-width: 480px) {
    }
`;

const Div2 = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 949px;
    align-items: flex-start;
    justify-content: space-between;
`;

export { Page, Cont, Frame, Title, Num, Txt, Projetos, DivProjetos, Text, Text2, Div2 };
