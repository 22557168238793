import React, { useCallback, useEffect, useState } from "react";
import createCTX from "../../utils/createCTX";
import useProjectsContext from ".";
import { Project } from "../../models/FB/projects";
import { useParams } from "react-router-dom";

interface ProjectInterface {
    project?: Project;
}

const [useProjectContext, ProjectContext] = createCTX<ProjectInterface>();

const ProjectProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { projects } = useProjectsContext();

    //get :id from url react router dom
    const { projectID } = useParams<{ projectID: string }>();

    const value: ProjectInterface = {
        project: projects.find((p) => p.id === projectID),
    };

    return <ProjectContext value={value}>{children}</ProjectContext>;
};

export default useProjectContext;
export { ProjectProvider };
