import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Camera from "../../assets/Camera.png";
import { Formik } from "formik";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../middlewares/firebase";
import Button from "../../components/buttom";
import { authMessageByCode } from "../../utils/authMenssagesCode";

const Page = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    height: 100vh;
`;

const Cont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 327.5px;
    /* background: red; */
`;

const Frame = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 327.5px;
`;

const Frame1 = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    /* width: 327.5px; */
    /* height: 128px; */
    /* margin-top: 6%; */
    /* background: red; */
`;

const Title = styled.h1`
    font-size: 30px;
    font-weight: 700;
    font-family: "Manrope";
    line-height: 0%;

    @media screen and (max-width: 480px) {
        /* width: 350px; */
    }
`;

const Txt = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
`;

const Foto = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background: #eff3f6;
    border-radius: 72px;
    /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
    box-shadow: #25313d44 3px 3px 6px 0px inset, #35455515 -3px -3px 6px 1px inset;
`;

const Input = styled.input`
    display: flex;
    width: 291px;
    height: 56px;
    border-radius: 16px;
    border: 0 none;
    outline: 0;
    /* background: #eff0ff; */
    padding: 0 18px;
    font-size: 16px;
    font-family: "Manrope";
    margin-top: 6%;
    /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
    box-shadow: #25313d44 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.63) -3px -3px 6px 1px inset;
`;

const Texto = styled.h4`
    font-family: "Manrope";
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #737b8c;
    text-align: start;
    /* align-items: flex-end; */
    /* padding: 0 5%; */

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Text = styled.h4`
    font-family: "Manrope";
    font-weight: 700;
    font-size: 14px;
    line-height: 0%;
    color: #737b8c;
    text-align: start;
    /* align-items: flex-end; */
    /* padding: 0 5%; */

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Btn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 327px;
    height: 56px;
    border-radius: 16px;
    background-color: #1238f1;
    border: 0;
    margin-top: 5%;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const BtnTitle = styled.h2`
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope";
    color: #fff;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Div = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Text2 = styled.h2`
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope";
    color: #737b8c;
    font-weight: 0%;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Text3 = styled.a`
    font-size: 16px;
    font-weight: 800;
    font-family: "Manrope";
    color: #aad63b;
    font-weight: 0%;
    margin-left: 5px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

const Upload = styled.h2`
    font-size: 10px;
    font-weight: 400;
    font-family: "Manrope";
    color: #737b8c;
    font-weight: 0%;
    margin-top: 8%;
    margin-bottom: -3%;

    @media screen and (max-width: 480px) {
        /* font-size: 12px; */
    }
`;

// const handleClick = () => {
//   window.scrollTo({ top: 0, behavior: "smooth" });
// };

const termos = <Text>Termos de Serviço</Text>;

const Cadastro: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    return (
        <Page>
            <Cont>
                <Frame>
                    <Txt>
                        <Title>Nova</Title>
                        <Title>Conta</Title>
                    </Txt>
                    <Foto>
                        <img src={Camera} alt="" />
                        <Upload>Upload</Upload>
                    </Foto>
                </Frame>
                <Formik
                    initialValues={{ email: "", nome: "", senha: "" }}
                    onSubmit={async (values) => {
                        try {
                            setLoading(true);
                            // if (values.senha !== values.confirmarSenha) {
                            //     console.log("Senhas não conferem");
                            //     return;
                            // }
                            await createUserWithEmailAndPassword(auth, values.email, values.senha);
                            navigate("/login");
                        } catch (error) {
                            const { code } = error as { code: string };
                            const message = authMessageByCode[code] || "Erro desconhecido";
                            setError(message);
                            console.log(error);
                        }
                        setLoading(false);
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <>
                            <Frame1>
                                <Input placeholder="Email" type="email" value={values.email} onChange={handleChange("email")} onBlur={handleBlur("email")} />
                                <Input placeholder="First Name" type="text" value={values.nome} onChange={handleChange("nome")} onBlur={handleBlur("nome")} />
                                <Input
                                    placeholder="Password"
                                    type="password"
                                    value={values.senha}
                                    onChange={handleChange("senha")}
                                    onBlur={handleBlur("senha")}
                                />
                                <Div>
                                    <Texto>Você concordou com nossos Termos de Serviço ao continuar a se inscrever.</Texto>
                                </Div>
                            </Frame1>
                            <Button loading={loading} onClick={() => handleSubmit()}>
                                <BtnTitle>Logar</BtnTitle>
                            </Button>
                        </>
                    )}
                </Formik>
                <Div>
                    <Text2>Já tem uma conta? </Text2>
                    <Text3
                        onClick={() => {
                            navigate("/login");
                        }}
                    >
                        Entrar
                    </Text3>
                </Div>
            </Cont>
        </Page>
    );
};

export default Cadastro;
