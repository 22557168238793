import React, { useCallback, useEffect, useState } from "react";
import createCTX from "../../utils/createCTX";
import { useParams } from "react-router-dom";
import useDeviceContext from "./device";
import { NativeDeviceCene } from "../../models/NativeDeviceDescriptions";

interface CenesDeviceInterface {
    cenesdevice?: Array<NativeDeviceCene>;
    loadding?: boolean;
    deleteLastCene: () => void;
    addCene: () => void;
    updateCene: (cene: Partial<NativeDeviceCene>, index: number) => void;
}

const [useCenesDeviceContext, CenesDeviceContext] = createCTX<CenesDeviceInterface>();

const CenesDeviceProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [cenesdevice, setCenesDevice] = useState<Record<number, NativeDeviceCene>>({});
    const { device } = useDeviceContext();

    console.table(cenesdevice);

    const { acoesID } = useParams<{ acoesID: string }>();

    useEffect(() => {
        (async () => {
            if (device?.controller && acoesID) {
                let count = 0;
                let results = false;
                const dataResults: Record<number, NativeDeviceCene> = {};
                do {
                    try {
                        const req = await device.controller.getACCTByPath<{
                            [P in keyof NativeDeviceCene]: string;
                        }>(`/cs/${acoesID}`, count);
                        const { code, data } = req.data;
                        const [getter] = code.split("");
                        if (getter === "0") break;
                        dataResults[count] = {
                            id: data.id,
                            path: data.path,
                            query: data.query,
                            invertToOff: data.invertToOff === "on",
                        };
                        count++;
                        results = getter === "1";
                    } catch (error) {
                        console.log(error);
                        results = false;
                    }
                } while (results);
                setCenesDevice(dataResults);
            }
        })();
    }, [acoesID, device?.controller]);

    const deleteLastCene = useCallback(() => {
        if (Object.keys(cenesdevice).length > 0) {
            const lastCene = Object.keys(cenesdevice).length - 1;
            try {
                device?.controller?.deleteACCTByPath(`/cs/${acoesID}`, lastCene);
                const newCenesDevice = { ...cenesdevice };
                delete newCenesDevice[lastCene];
                console.log({ newCenesDevice });
                setCenesDevice(newCenesDevice);
            } catch (error) {
                console.log(error);
            }
        }
    }, [acoesID, cenesdevice, device?.controller]);

    const updateCene = useCallback(
        (cene: Partial<NativeDeviceCene>, index: number) => {
            const newCenesDevice = { ...cenesdevice };
            try {
                device?.controller?.updateACCTByPath(`/cs/${acoesID}`, {
                    ...cene,
                    $n: index,
                });
                newCenesDevice[index] = {
                    ...newCenesDevice[index],
                    ...cene,
                };
                setCenesDevice(newCenesDevice);
            } catch (error) {}
        },
        [acoesID, cenesdevice, device?.controller]
    );

    const addCene = useCallback(() => {
        // if (cene) {
        const newCenesDevice = { ...cenesdevice };
        newCenesDevice[Object.keys(cenesdevice).length] = {
            id: "",
            path: "",
            query: "",
            invertToOff: false,
        };
        setCenesDevice(newCenesDevice);
        // }
    }, [cenesdevice]);

    const value: CenesDeviceInterface = {
        cenesdevice: Object.values(cenesdevice),
        deleteLastCene,
        addCene,
        updateCene,
    };

    return <CenesDeviceContext value={value}>{children}</CenesDeviceContext>;
    
};

export default useCenesDeviceContext;
export { CenesDeviceProvider };
