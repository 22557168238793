import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import Drop from '../../assets/Drop.svg'

export const Drops = styled.div`
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    top: 120%;
    left: 0;
    right: 0;
    text-align: left;
    font-family: "Manrope";
    max-height: 300px;
    overflow-y: scroll;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: #25313d44 3px 4px 3px 0px, #25313d16 -1px -1px 3px 1px;

    @media screen and (max-width: 480px) {
        /* max-height: 300px; */
    }
`;

export const P = styled.h4`
    margin: 0;
    padding: 3% 0 3% 1vw;
    height: 19px;
    /* width: 270px; */
    font-family: "Manrope";
    background: #ffffff;

    :hover {
        background-color: #eff3f6;
    }

    :last-child {
        border-bottom: 0 none;
    }
`;

// const DropD = <img style={{ paddingLeft: "3.5em" }} src={Drop} alt=" " /> ;

type DropDownProps = {
    opcoes: string[];
    showDropDown: boolean;
    toggleDropDown: Function;
    opcaoSelection: Function;
};

const DropDown: React.FC<DropDownProps> = ({ opcoes, opcaoSelection }: DropDownProps): JSX.Element => {
    const [showDropDown, setShowDropDown] = useState<boolean>(false);

    /**
     * Handle passing the estado name
     * back to the parent component
     *
     * @param estado  The selected estado
     */
    const onClickHandler = (opcao: string): void => {
        opcaoSelection(opcao);
    };

    useEffect(() => {
        setShowDropDown(showDropDown);
    }, [showDropDown]);

    return (
        <>
            <Drops>
                {opcoes.map((opcao: string, index: number): JSX.Element => {
                    return (
                        <P
                            key={`dop-chosen-${opcao}-${index}`}
                            onClick={(): void => {
                                onClickHandler(opcao);
                            }}
                        >
                            {opcao}
                        </P>
                    );
                })}
            </Drops>
        </>
    );
};

export default DropDown;
