import React, { FC } from "react";
import { BodySession, NavSession, Page, TopBar } from "./style";
import { Outlet } from "react-router-dom";
import PerfilElement from "../../components/Perfil";
import styled from "styled-components";
import MenuHome from "./menu";

const Session = styled.div`
    padding-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export interface RootHomeElementsProps {}

const RootHomeElements: FC<RootHomeElementsProps> = ({}) => {
    return (
        <Page>
            {/* <div
                style={{
                    background: "#eff3f6",
                    position: "absolute",
                    left: 0,
                    width: 'calc(200px + (100vw - 600px - 1400px))', // 1400 max 
                    height: "100%",
                    zIndex: -1, 
                }}
            /> */}
            <NavSession>
                <MenuHome />
            </NavSession>
            <BodySession>
                <Session>
                    <TopBar>
                        <div></div>
                        <PerfilElement />
                    </TopBar>
                    <Outlet />
                </Session>
            </BodySession>
        </Page>
    );
};

export default RootHomeElements;
